import React from 'react';
import './App.css';
import "@mui/material/styles";
import MapComponent from './MapComponent';
import ContributionComp from "./ContributionComp";
import ContributionAdmin from "./ContributionAdmin";

function App() {
  return (
      <div className="App">
          <MapComponent/>
          {/*<ContributionAdmin/>*/}
      </div>
  );
}

export default App;
