import React, { useState, useEffect } from 'react';
import './decade_map.css'
import { Button, TextField, Select, FormControl, InputLabel, MenuItem, FormHelperText, Typography} from '@mui/material';import {set} from "react-hook-form";

function ContributionComp(props) {
    const [formData, setFormData] = useState({
        year: "",
        address: props.selectedAddress,
        occupant: "",
        owner: "",
        historicalElement: "",
        historicalArtifact: "",
        sources: "",
        notes: "",
        mgrs: "",

        contFirstName: "",
        contLastName: "",
        contEmail: "",
        contCons: "", // YesNo Contributor Consent

    });
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',  // added address error state
    });    const [page, setPage] = useState(1);

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            address: props.selectedAddress
        }));

        setError(prevError => ({
            ...prevError,
            address: props.selectedAddress ? '' : 'address is required'
        }));
    }, [props.selectedAddress]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'address') {
            props.setSelectedAddress(value);  // update selectedAddress when typing in the address field
        }

        // error handling
        setError(prev => ({
            ...prev,
            [name]: value ? "" : name + ' is required'
        }));

        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // check if required fields are empty
        let newErrors = {};
        if (!formData.contFirstName) newErrors.firstName = 'firstName is required';
        if (!formData.contLastName) newErrors.lastName = 'lastName is required';
        if (!formData.contEmail) newErrors.email = 'email is required';
        if (!formData.address) newErrors.address = 'address is required';

        // if there are errors, update the error state and don't submit the form
        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
            return;
        }

        fetch('http://localhost:8000/src/submitData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData).toString(),
        })
            .then((response) => response.text())
            .then(data => {
                if (data === 'Records inserted successfully.') {
                    alert('Thank you for your submission! It will be reviewed and added shortly.');
                    props.setShowContribution(false);
                } else {
                    alert('An error occurred: ' + data);
                }
            })
        .catch((error) => console.error(error));
    }
    return (
        <div style={{ position: 'absolute', right: 0, top: 0, width: '33vw', minWidth: 300, height: '100vh', overflowY: 'scroll', overflowX: 'hidden', background: '#ebe9e8', zIndex: 1000}}>
            <form className='form-container' onSubmit={handleSubmit}>
                {page === 1 ? (
                    <div className='form-container1'>
                        <Typography className='form-text'>Use the form below to contribute data to the project.</Typography>
                        <br/>
                        <FormControl className='form-year'>
                            <InputLabel>Year</InputLabel>
                            <Select
                                label="YEAR"
                                name="year"
                                value={formData.year}
                                onChange={handleChange}
                            >
                                <MenuItem value="">select</MenuItem>
                                <MenuItem value="1900">1900</MenuItem>
                                <MenuItem value="1910">1910</MenuItem>
                                <MenuItem value="1920">1920</MenuItem>
                                <MenuItem value="1930">1930</MenuItem>
                                <MenuItem value="1940">1940</MenuItem>
                                <MenuItem value="1950">1950</MenuItem>
                                <MenuItem value="1960">1960</MenuItem>
                                <MenuItem value="1970">1970</MenuItem>
                                <MenuItem value="1980">1980</MenuItem>
                                <MenuItem value="1990">1990</MenuItem>
                            </Select>
                            <FormHelperText>Please select the decade that best fits the information being submitted.</FormHelperText>

                        </FormControl>
                        <br/>
                        <TextField
                            label="Address"
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className='form-input'
                            error={Boolean(error.address)}
                            helperText={error.address}
                        />
                        <Button variant="outlined" onClick={() => props.setSelectMode(true)}>Select on Map</Button>
                        <br/>
                        <TextField
                            label="Occupant"
                            type="text"
                            name="occupant"
                            value={formData.occupant}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Owner"
                            type="text"
                            name="owner"
                            value={formData.owner}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Historical Element"
                            type="text"
                            name="historicalElement"
                            value={formData.historicalElement}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Historical Artifact"
                            type="text"
                            name="historicalArtifact"
                            value={formData.historicalArtifact}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Source"
                            type="text"
                            name="sources"
                            value={formData.sources}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Notes"
                            type="text"
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <div><Button variant="outlined" onClick={() => setPage(2)}>NextPage</Button></div>
                    </div>
                ) : (
                    <div className='form-container2'>
                        <Typography>Please fill in your information below.</Typography>
                        <TextField
                            label="First Name"
                            type="text"
                            name="contFirstName"
                            value={formData.contFirstName}
                            onChange={handleChange}
                            className='form-input'
                            error={Boolean(error.firstName)}
                            helperText={error.firstName}
                        />
                        <TextField
                            label="Last Name"
                            type="text"
                            name="contLastName"
                            value={formData.contLastName}
                            onChange={handleChange}
                            className='form-input'
                            error={Boolean(error.lastName)}
                            helperText={error.lastName}
                        />
                        <TextField
                            label="Email"
                            type="email"
                            name="contEmail"
                            value={formData.contEmail}
                            onChange={handleChange}
                            className='form-input'
                            error={Boolean(error.email)}
                            helperText={error.email}
                        />
                        <Typography className='consent'>I consent to be contacted in the future in regards to the project.</Typography>
                        {error.address && <Typography className={'form-error'}>{error.address}</Typography>}
                        <div style={{padding: "1.5vh"}}><Button variant="contained" style={{padding: "1vh"}} type="submit">Submit</Button></div>
                    </div>
                )}
            </form>
            <div className="cont-back-button"><Button variant="outlined" onClick={() => setPage(1)}>Back</Button></div>
        </div>
    );

}

export default ContributionComp;
