import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, CircleMarker, ImageOverlay, ImageOverlayProps, LayersControl, useMapEvents } from 'react-leaflet';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {Typography} from "@mui/material";
import {Button} from "@mui/material";
import {Stack} from "@mui/material";
import "@mui/material/styles";
import 'leaflet/dist/leaflet.css';
import './decade_map.css';
import './web19201.css';
import ContributionComp from "./ContributionComp";

const DEFAULT_POSITION = [43.064879, -87.952869];
const DEFAULT_ZOOM = 15.5;

const marks = [
    {
        value: 1900,
        label: '1900',
    },
    {
        value: 1910,
        label: '1910',
    },
    {
        value: 1920,
        label: '1920',
    },
    {
        value: 1930,
        label: '1930',
    },
    {
        value: 1940,
        label: '1940',
    },
    {
        value: 1950,
        label: '1950',
    },
    {
        value: 1960,
        label: '1960',
    },
    {
        value: 1970,
        label: '1970',
    },
    {
        value: 1980,
        label: '1980',
    },
    {
        value: 1990,
        label: '1990',
    },

];

const MiniMap = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
};

function valuetext(value) {
    return `${value}`;
}

function ChangeView({ center, zoom }) {
    const map = useMap();

    useEffect(() => {
        map.setView(center, zoom);
    }, [center, zoom]);

    return null;
}

/*function MiniMapMarker({ lat, lng }) {
    const [markerPos, setMarkerPos] = useState([lat, lng]);

    useEffect(() => {
        setMarkerPos([lat, lng]);
    }, [lat, lng]);

    return (
        <MapContainer
            style={{ height: "150px", width: "150px" }}
            center={[lat, lng]}
            zoom={20}
            dragging={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            zoomControl={false}
            attributionControl={false}
        >
            <CircleMarker
                center={markerPos}
                color="#4a68bd"
                fillOpacity={1}
                radius={5}
                weight={0}
            />
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
        </MapContainer>
    );
}*/

function MapComponent() {
    const [position, setPosition] = useState(DEFAULT_POSITION);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [year, setYear] = useState(1950);
    const [pageYear, setPageYear] = useState(1950)
    const [data, setData] = useState([]);
    const [mapdata, setMapdata] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [showContribution, setShowContribution] = useState(false);
    const [opacity, setOpacity] = useState(1); // start with 100% opacity
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedAddressLatLng, setSelectedAddressLatLng] = useState(null)
    const [selectMode, setSelectMode] = useState(false);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);


    const MapEventsHandler = () => {

        const map = useMap();  // get a reference to the map instance

        useEffect(() => {
            const leafletContainer = map.getContainer();
            if (selectMode) {
                leafletContainer.classList.add('selecting');
            } else {
                leafletContainer.classList.remove('selecting');
            }
        }, [map, selectMode]);
        useMapEvents({
            click: async (e) => {
                if (selectMode) {
                    const apiKey = "1ed9ef64e63a4fe795c36e6c5e174d92";
                    const { lat, lng } = e.latlng;
                    const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=${apiKey}`);
                    if (response.ok) {
                        const geocodeData = await response.json();
                        if (geocodeData.results && geocodeData.results.length > 0) {
                            const address = geocodeData.results[0].formatted;
                            setSelectedAddress(address);
                            console.log(address)
                            setSelectedAddressLatLng(e.latlng)
                            setSelectMode(false); // reset selectMode after successful selection
                        } else {
                            console.error('No results found for these coordinates');
                        }
                    } else {
                        console.error(`Geocoding error: ${response.status}`);
                    }
                }
            }
        });
        return null;

    };

    useEffect(() => {
        const geocodeAddress = async () => {
            const apiKey = "1ed9ef64e63a4fe795c36e6c5e174d92";
            const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(selectedAddress)}&key=${apiKey}`);
            if (response.ok) {
                const geocodeData = await response.json();
                if (geocodeData.results && geocodeData.results.length > 0) {
                    const { geometry } = geocodeData.results[0];
                    setSelectedAddressLatLng({lat: geometry.lat, lng: geometry.lng});
                } else {
                    console.error('No results found for this address');
                }
            } else {
                console.error(`Geocoding error: ${response.status}`);
            }
        }

        if (selectedAddress) {
            geocodeAddress();
        }
    }, [selectedAddress]);





    const handleContr = () => {
        setShowContribution(!showContribution);
    }


    const fetchDataForMarker = async (markerId) => {


        try {
            const response = await fetch(`https://mapsite.whoseland.work/api/fetchDataForMarker.php?markerId=${markerId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            let rawData = await response.json();
            let newData = Object.entries(rawData).map(([key, value]) => {
                let yearP = key.replace("wl.", "").replace("_hp", "");
                let data = value[0];
                return {
                    year: yearP,
                    data: {
                        occ: data[`occ${yearP.slice(-2)}`],
                        own: data[`own${yearP.slice(-2)}`],
                        artifact: data[`artifact${yearP.slice(-2)}`],
                        element: data[`element${yearP.slice(-2)}`],
                        source: data[`source${yearP.slice(-2)}`],
                        note: data[`note${yearP.slice(-2)}`],
                        ...data,
                    }
                };
            });
            setData(newData);
        setSelectedItems(newData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            // Handle error appropriately but don't set selectedItems to null
        }
    };

    useEffect(() => {
        // Find the index of the item for the current decade.
        const index = selectedItems.findIndex(item => item.year === year);

        // If an item for the current decade was found, update currentPage.
        if (index !== -1) {
            setCurrentPage(index);
        }
    }, [year, selectedItems]);


    useEffect(() => {
        const fetchDataForYear = async (year) => {

            let suffix = year.toString().slice(-2);

            // Create the property names
            let occProp = "occ" + suffix;
            let ownProp = "own" + suffix;
            let artifactProp = "artifact" + suffix;
            let elementProp = "element" + suffix;
            let sourceProp = "source" + suffix;
            let noteProp = "note" + suffix;

            try {
                const response = await fetch(`https://mapsite.whoseland.work/api/vardecselect.php?year=${year}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                let fetchedData = await response.json();
                // Adjust the property names in fetchedData to the dynamic ones
                fetchedData = fetchedData.map(item => ({
                    ...item,
                    occ: item[occProp],
                    own: item[ownProp],
                    artifact: item[artifactProp],
                    element: item[elementProp],
                    source: item[sourceProp],
                    note: item[noteProp],
                }));

                setMapdata(fetchedData);
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };
        fetchDataForYear(year);
    }, [year]);


    return (
        <div style={{ position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw'}}>
            <div className={`map-container ${isSidePanelOpen ? 'with-side-panel' : ''}`}>
                 <div style={{height: "10vh", width: "40vw", minWidth: 300, position: 'absolute', top: 20, right: 70, zIndex: 1000}}>
                    <Box sx={{ width: "100%", position: 'absolute', right: 10}}>
                        {/*<Typography id="time-slider" gutterBottom>
                            Time-Slider
                        </Typography>*/}
                        <Slider
                            aria-label="Time-Slider"
                            defaultValue={1950}
                            getAriaValueText={valuetext}
                            min={1900}
                            max={1990}
                            step={10}
                            valueLabelDisplay="auto"
                            marks={marks}
                            onChange={(e, newValue) => setYear(newValue.toString())}
                        />
                    </Box>
                 </div>
                <div style={{height: "10%", width: "100%", minWidth: 300, display: 'flex', position: 'relative', top: '90%', left: 0, alignItems: "center", justifyContent: "center", zIndex: 1000}}>
                    <Box sx={{ width: "50%", position: 'relative'}}>
                        <Typography id="opacity" gutterBottom variant="h6" className='opacitysliderlabel' >
                            Map Layer Opacity
                        </Typography>
                        <Slider
                            aria-label="Opacity"
                            defaultValue={1}
                            min={0}
                            max={1}
                            step={0.01}
                            valueLabelDisplay="auto"
                            onChange={(e, newValue) => setOpacity(newValue)}
                        />
                    </Box>
                </div>
                <MapContainer
                    center={position}
                    zoom={zoom}
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                >
                    <ChangeView center={position} zoom={zoom} />
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="Base Map">
                            <TileLayer
                                attribution='&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a> contributors'
                                url="https://tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=qLd8bVKCmNU0nymV0yHp7S52X2zfLe4c4XM3isuV6dWTOnP8tmsIoJPlRFN4pUji"
                                //url="https://mapwarper.net/maps/tile/20550/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.Overlay name="1922 Milwaukee">
                            <TileLayer
                                url="https://mapwarper.net/maps/tile/20550/{z}/{x}/{y}.png"
                                opacity={opacity}
                            />
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name="1937 Milwaukee HOLC Redlining">
                            <TileLayer
                                url="https://mapwarper.net/maps/tile/51189/{z}/{x}/{y}.png"
                                opacity={opacity}
                            />
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name="1936 Milwaukee Zoning">
                            <TileLayer
                                url="https://mapwarper.net/maps/tile/74486/{z}/{x}/{y}.png"
                                opacity={opacity}
                            />
                        </LayersControl.Overlay>
                        {/*<LayersControl.Overlay name="1922 Milwaukee">
                            <TileLayer
                                url="https://mapwarper.net/maps/tile/20550/{z}/{x}/{y}.png"
                                opacity={opacity}
                            />
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name="1922 Milwaukee">
                            <TileLayer
                                url="https://mapwarper.net/maps/tile/20550/{z}/{x}/{y}.png"
                                opacity={opacity}
                            />
                        </LayersControl.Overlay>*/}
                    </LayersControl>
                    {mapdata.map((item, index) => (
                        <CircleMarker
                            key={index}
                            center={[item.lat, item.lng]}
                            eventHandlers={{
                                click: () => {
                                    fetchDataForMarker(item.id_add).then(() => {
                                        setCurrentPage(0);
                                        setIsSidePanelOpen(true);
                                    });
                                }
                            }}
                            color="#4a68bd"
                            fillOpacity={1}
                            radius={3.5}
                            weight={0}
                        >
                            {/*<Popup>
                                <MiniMapMarker lat={item.lat} lng={item.lng}/>
                            </Popup>*/}
                        </CircleMarker>
                    ))}
                    {selectedAddressLatLng &&
                        <CircleMarker
                            center={selectedAddressLatLng}
                            pathOptions={{color: "red", fillColor: "red"}}
                            fillOpacity={1}
                            radius={6}
                            weight={0}
                        >
                            <Popup>
                                <Typography>Selected Address</Typography>
                            </Popup>
                        </CircleMarker>
                    }
                    <MapEventsHandler/>
                <Button variant="contained" className='contributemapbutton' onClick={() => {setShowContribution(true); setIsSidePanelOpen(true)}}>Contribute</Button>
                </MapContainer>
            </div>
            {isSidePanelOpen && (
                <>
                    <div className='sidepanelclose-div'>
                        <div style={{position:"absolute", top: '10px', right:'10px', zIndex: 2000}}>
                            <Button variant="outlined" onClick={() => setIsSidePanelOpen(false)}>Close</Button>
                        </div>
                    </div>
                    {selectedItems.length > 0 && (
                        <div className='sidepanel'>
                            <div data-layer="f9cd7771-1cca-4304-b8a1-5e0053c24bbb" className="web19201">
                                <div data-layer="97a51b9e-e8b6-4065-902a-510856fb5962" className="rectangle2">
                                    <div data-layer="9634d2f2-5cc2-42fe-a577-937e4f488fa8" className="xYear">{(selectedItems[currentPage].year)}</div>
                                </div>
                                {/*<svg data-layer="3c70239a-07c1-489b-8a8c-ae11c8c10ee1" preserveAspectRatio="none" viewBox="12.885000228881836 9 61.75 100" className="iconMaterialNavigateNext" onClick={() => setCurrentPage(oldPage => Math.min(selectedItems.length - 1, oldPage + 1))} role="button" tabIndex="0">
                                    <path d="M 24.63500022888184 9 L 12.88500022888184 20.74999618530273 L 51.05166625976562 59 L 12.88500022888184 97.25 L 24.63500022888184 109 L 74.63500213623047 59 L 24.63500022888184 9 Z" />
                                </svg>
                                <svg data-layer="142a2239-8270-4b1b-bc6b-3c6408c210bc" preserveAspectRatio="none" viewBox="11.999996185302734 9 61.7499885559082 100" className="iconMaterialNavigateBefore" onClick={() => setCurrentPage(oldPage => Math.max(0, oldPage - 1))} role="button" tabIndex="0">
                                    <path d="M 73.74998474121094 20.74999618530273 L 61.99999618530273 9 L 11.99999618530273 59 L 61.99999618530273 109 L 73.74998474121094 97.25 L 35.58333206176758 59 L 73.74998474121094 20.74999618530273 Z"  />
                                </svg>*/}
                            </div>
                            <div className="page-list">
                                <span className='page-header'>{selectedItems[currentPage].data.occ}</span>
                                {selectedItems[currentPage].data.artifact && <img src={selectedItems[currentPage].data.artifact} alt="Image" style={{ width: "300px" }} />}
                                <ul className='page-list-container'>
                                    <li className='page-item'><span className='page-item-key'>Address: </span>{selectedItems[currentPage].data.add}</li>
                                    <li className='page-item'><span className='page-item-key'>Occupant: </span>{selectedItems[currentPage].data.occ}</li>
                                    <li className='page-item'><span className='page-item-key'>Owner: </span>{selectedItems[currentPage].data.own}</li>
                                    <li className='page-item'><span className='page-item-key'>Historical Element: </span>{selectedItems[currentPage].data.element}</li>
                                    <li className='page-item'><span className='page-item-key'>Historical Artifact: </span>{selectedItems[currentPage].data.artifact}</li>
                                    <li className='page-item'><span className='page-item-key'>Sources: </span>{selectedItems[currentPage].data.source}</li>
                                    <li className='page-item'><span className='page-item-key'>Notes: </span>{selectedItems[currentPage].data.note}</li>
                                    <li className='page-item'><span className='page-item-key'>MGRS: </span>{selectedItems[currentPage].data.id_add}</li>
                                </ul>
                                <Button variant="outlined" className="page-button" onClick={() => setCurrentPage(oldPage => Math.max(0, oldPage - 1)) && setPageYear(selectedItems[currentPage].year)}>Previous</Button>
                                <Button variant="outlined" className="page-button" onClick={() => setCurrentPage(oldPage => Math.min(selectedItems.length - 1, oldPage + 1)) && setPageYear(selectedItems[currentPage].year)}>Next</Button>
                                <br/>
                                <br/>
                                <Button variant="outlined" onClick={handleContr}>Contribute</Button>
                            </div>
                        </div>
                    )}
                    {showContribution &&
                        <div>
                            <div style={{position:"absolute", top: '10px', right:'10px', zIndex: 2000}}>
                                <Button variant="outlined" onClick={() => {setShowContribution(false); setSelectedAddressLatLng(null); setIsSidePanelOpen(false)}}>Close</Button>
                            </div>
                            <ContributionComp setShowContribution={setShowContribution} selectedAddress={selectedAddress} setSelectMode={setSelectMode} setSelectedAddress={setSelectedAddress}/>
                        </div>
                    }
                </>
            )}
        </div>
    );
}

export default MapComponent;
